<template>
  <div class="back">
    <div class="d-flex flex-column align-content-center ma-auto py-8">
      <v-card
          class="pa-5 elevation-6"
          width="1024">
        <v-card-text style="text-align: center">
          <div class="text-h4 text--primary font-weight-regular">
            军事文本事件抽取
          </div>
          <!--        <div class="text&#45;&#45;primary mt-3">-->
          <!--          小标题-说点啥-->
          <!--        </div>-->
        </v-card-text>
        <v-card-text class="mt-4">
          <v-form ref="loginForm">
            <v-text-field
                v-model="baseUrl"
                label="Base Url"
                required
            ></v-text-field>
            <v-textarea
                outlined
                clearable
                clear-icon="mdi-close"
                label="事件抽取"
                placeholder="请输入一句军事文本，字数不超过200"
                counter
                rows="6"
                v-model="text"
                :rules="[rules.required, rules.maxLength]"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions style="width: 100%">
          <v-row justify="space-between">
            <v-col md="6" style="text-align: left">
            </v-col>
            <v-col md="6" style="text-align: right" class="pr-5">
              <v-btn
                  large
                  ref="loginBtn"
                  color="primary"
                  @click="submit"
                  :loading="loginBtnLoader"
                  :disabled="loginBtnLoader">
                <div class="mx-3 text-h6">抽&nbsp;&nbsp;取</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-card v-if="formData"
              class="elevation-6 pr-5"
              width="1024">
        <v-card-text>
          <v-timeline
              align-top
              dense>
            <v-timeline-item
                v-for="message in formData"
                :key="message.trigger"
                small>
              <div>
                <div class="font-weight-normal text-h6 mb-4">
                  <strong>触发词 {{ message.trigger }}</strong>
                </div>
                <div class="mb-2">
                  <strong>事件类型</strong> {{ message.event_type }}
                </div>
                <div class="mb-2">
                  <strong>起始位置</strong> {{ message.trigger_start_index }}
                </div>
                <div>
                  <v-data-table
                      :headers="headers"
                      :items="message.arguments"
                      :items-per-page="5"
                      class="elevation-1"
                  ></v-data-table>
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
        v-model="snackbar">
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
//import api from "@/api/api";
import axios from "axios";

export default {
  name: 'HelloWorld',

  data: () => ({
    text: "",
    baseUrl: "http://localhost:8081",
    loginBtnLoader: false,
    accountData: {
      account: "",
      password: "",
    },
    rules: {
      required: value => !!value || '必填项',
      maxLength: value => value.length <= 500 || 'Max 500 characters'
    },
    snackbar: false,
    errorMsg: `Hello, I'm a snackbar`,
    headers: [{text: '论元位置', value: 'argument_start_index'}, {text: '论元角色', value: 'role'}, {
      text: '论元',
      value: 'argument'
    }],
    formData: null,
    // formData: [
    //   {
    //     "trigger": "清剿",
    //     "event_type": "军事-军事行动",
    //     "trigger_start_index": 46,
    //     "arguments": [
    //       {
    //         "argument_start_index": 0,
    //         "role": "subject",
    //         "argument": "步兵155团、157团和32团2营"
    //       },
    //       {
    //         "argument_start_index": 18,
    //         "role": "time",
    //         "argument": "21日上午"
    //       },
    //       {
    //         "argument_start_index": 26,
    //         "role": "loc",
    //         "argument": "克节朗河"
    //       }
    //     ]
    //   },
    //   {
    //     "trigger": "占领",
    //     "event_type": "军事-军事行动",
    //     "trigger_start_index": 60,
    //     "arguments": [
    //       {
    //         "argument_start_index": 0,
    //         "role": "subject",
    //         "argument": "步兵155团、157团和32团2营"
    //       },
    //       {
    //         "argument_start_index": 18,
    //         "role": "time",
    //         "argument": "21日上午"
    //       },
    //       {
    //         "argument_start_index": 63,
    //         "role": "object",
    //         "argument": "桑采拉"
    //       },
    //       {
    //         "argument_start_index": 71,
    //         "role": "loc",
    //         "argument": "吉山口"
    //       }
    //     ],
    //   }
    // ],

  }),
  methods: {
    async submit() {

      this.loginBtnLoader = true;
      this.formData = null;
      let result = await axios.post(`${this.baseUrl}/analysis`, {text: this.text}).catch(err => {
        this.errorMsg = err;
        this.snackbar = true;
      })
      this.loginBtnLoader = false;
      if (result.status !== 200) {
        return;
      }
      //let result = await api.analysis({text: this.text})
      if (result.data.ok !== true) {
        this.errorMsg = result.data.errorMsg;
        this.snackbar = true;
        return;
      }
      this.formData = result.data.data;
    }
  }
}
</script>

<style scoped>
.back {
  background-image: url("//i.impiggy.cn/images/2022/01/14/material.jpg");
  height: 100%;
  width: 100%;
  background-size: cover;
  display: flex;
}
</style>

