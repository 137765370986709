<template>
  <v-app>
    <v-main>
      <div style="height: 100%; width: 100%">
        <HelloWorld/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>
